<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" id="account_type_update" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                        <h3 class="card-label">
                            Sửa loại tài khoản
                        </h3>
                    </div>
                    <div class="card-toolbar">

                        <router-link
                                class="font-weight-bold font-size-3  btn btn-secondary"
                                :to="{ name: 'account-type-index' }"
                        ><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="row mx-auto mt-8">
                        <div class="col-md-6">
                            <label class="col-xl-4 col-lg-3 col-form-label text-left mr-5">Ảnh đại diện</label>
                            <div class="col-lg-9 col-xl-6 mb-4">
                                <div class="image-input image-input-outline" id="kt_profile_avatar">
                                    <div
                                            class="image-input-wrapper"
                                            :style="{ backgroundImage: `url(${photo})` }"
                                    ></div>
                                    <label
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="change"
                                            data-toggle="tooltip"
                                            title=""
                                            data-original-title="Change avatar"
                                    >
                                        <i class="fa fa-pen icon-sm text-muted"></i>
                                        <input
                                                type="file"
                                                name="profile_avatar"
                                                accept=".png, .jpg, .jpeg"
                                                @change="onFileChange($event)"
                                        />
                                        <input type="hidden" name="profile_avatar_remove"/>
                                    </label>
                                    <span
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="cancel"
                                            data-toggle="tooltip"
                                            title="Cancel avatar"
                                    >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                    <span
                                            class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                            data-action="remove"
                                            data-toggle="tooltip"
                                            title="Remove avatar"
                                            @click="current_photo = null"
                                    >
                                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                                </span>
                                </div>
                                <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tên loại tài khoản
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="name" name="Tên loại tài khoản" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control"
                                           placeholder="Tên loại tài khoản"
                                           v-model="account_type.name" :class="classes">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Là tài khoản
                                    <span class="text-danger">*</span></label>
                                <select name="is_staff" class="form-control" v-model="account_type.is_staff">
                                    <option value="1">Nhân viên Edutalk</option>
                                    <option value="0">Khách hàng</option>
                                </select>
                            </div>
                        </div>
                    </div>
<!--                    <div class="row">-->
<!--                        <div class="col-md-4">-->
<!--                            <div class="form-group">-->
<!--                                <label>Chọn quyền (Backend)-->
<!--                                    <span class="text-danger"></span></label>-->
<!--                              <el-select v-model="account_type.backend_role_id" clearable filterable class="w-100" placeholder="Chọn quyền">-->
<!--                                <el-option-->
<!--                                    v-for="item in backend_roles"-->
<!--                                    :label="item.name"-->
<!--                                    :value="item.id"-->
<!--                                >-->
<!--                                  <span style="float: left">{{ item.name }}</span>-->
<!--                                </el-option>-->
<!--                              </el-select>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;end::Form&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="col-md-4">-->
<!--                            <div class="form-group">-->
<!--                                <label>Chọn quyền (Center)-->
<!--                                    <span class="text-danger"></span></label>-->
<!--                              <el-select v-model="account_type.center_role_id" clearable filterable class="w-100" placeholder="Chọn quyền">-->
<!--                                <el-option-->
<!--                                    v-for="item in center_roles"-->
<!--                                    :label="item.name"-->
<!--                                    :value="item.id"-->
<!--                                >-->
<!--                                  <span style="float: left">{{ item.name }}</span>-->
<!--                                </el-option>-->
<!--                              </el-select>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;end::Form&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="col-md-4">-->
<!--                            <div class="form-group">-->
<!--                                <label>Chọn quyền (Nội Bộ)-->
<!--                                    <span class="text-danger"></span></label>-->
<!--                              <el-select v-model="account_type.noibo_role_id" clearable filterable class="w-100" placeholder="Chọn quyền">-->
<!--                                <el-option-->
<!--                                    v-for="item in noibo_roles"-->
<!--                                    :label="item.name"-->
<!--                                    :value="item.id"-->
<!--                                >-->
<!--                                  <span style="float: left">{{ item.name }}</span>-->
<!--                                </el-option>-->
<!--                              </el-select>-->
<!--                            </div>-->
<!--                            &lt;!&ndash;end::Form&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                               <label>Chọn quyền (Nội Bộ)
                                   <span class="text-danger"></span></label>
                             <el-select v-model="account_type.noibo_role_id" clearable filterable class="w-100" placeholder="Chọn quyền">
                               <el-option
                                   v-for="item in noibo_roles"
                                   :label="item.name"
                                   :value="item.id"
                               >
                                 <span style="float: left">{{ item.name }}</span>
                               </el-option>
                             </el-select>
                           </div>
                       </div>
                       <div class="col-md-6">
                            <div class="form-group">
                                    <label>Chọn quyền (Backend)
                                        <span class="text-danger"></span></label>
                                <el-select v-model="account_type.backend_role_id" clearable filterable class="w-100" placeholder="Chọn quyền">
                                    <el-option
                                        v-for="item in backend_roles"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    <span style="float: left">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                       </div>

                    </div>

                </div>
                <!--Thông tin cấp trên-->
                <div class="separator separator-dashed my-5"></div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button type="submit" class="btn btn-success mr-2" :disabled="is_disable">
                            Cập nhật
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>
<script>
    import {mapGetters} from "vuex";
    import Multiselect from 'vue-multiselect'
    import {
        GET_ROLES,
        GET_ACCOUNT_TYPE_BY_ID, UPDATE_ACCOUNT_TYPE
    } from "@/core/services/store/user/account-type.module";
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

    export default {
        components: {
            Multiselect,
        },
        name: "AccountTypeCreate",
        data() {
            return {
                account_type: {
                    name: '',
                    backend_role_id: '',
                    noibo_role_id: '',
                    center_role_id: '',
                    is_staff: 0,
                },
                /*photo*/
                default_photo: "media/users/blank.png",
                current_photo: null,
                image_name: null,
                image_type: null,
                /*End*/

                cacheRoleBackend: [],
                cacheRoleNoibo: [],
                cacheRoleCenter: [],
                backend_roles: [],
                noibo_roles: [],
                center_roles: [],
                is_disable: false,
            }
        },
        computed: {
            ...mapGetters(['getTeacherById']),
            photo() {
                return this.current_photo == null
                    ? this.default_photo
                    : this.current_photo;
            },
        },
        async created() {
        },
        async mounted() {
            await this.getAccountTypeById()
            await this.getRoles()
            await this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Tài khoản", route: 'account'},
                {title: "Loại tài khoản"}
            ]);
        },
        methods: {
            getAccountTypeById() {
                let id = this.$route.params.id;
                if (id) {
                    this.$store.dispatch(GET_ACCOUNT_TYPE_BY_ID, id).then((res) => {
                        if (!res.error) {
                            this.current_photo = res.data.avatar;
                            let account_type = res.data
                            this.account_type = account_type
                            if(res.data.center_role_id===0){
                              this.account_type.center_role_id = '';
                            }
                            if(res.data.noibo_role_id===0){
                              this.account_type.noibo_role_id = '';
                            }
                            if(res.data.backend_role_id===0){
                              this.account_type.backend_role_id = '';
                            }
                            if (account_type.backend_roles) {
                                this.cacheRoleBackend = account_type.backend_roles
                            }
                            if (account_type.center_roles) {
                                this.cacheRoleCenter = account_type.center_roles
                            }
                            if (account_type.noibo_roles) {
                                this.cacheRoleNoibo = account_type.noibo_roles
                            }
                        }
                    });
                }
            },
            updateStateRole(obj) {
                let id = obj.id
                let guard_name = obj.guard_name
                if (guard_name == 'backend') {
                    this.account_type.backend_role_id = id
                }
                if (guard_name == 'educenter') {
                    this.account_type.center_role_id = id
                }
                if (guard_name == 'noibo') {
                    this.account_type.noibo_role_id = id
                }
            },
            /*Get roles*/
            getRoles() {
                this.$store.dispatch(GET_ROLES, {
                    limit: 100
                }).then((res) => {
                        res.data.data.map((value) => {
                            if (value.guard_name == 'backend') {
                                this.backend_roles.push(value)
                            }
                            if (value.guard_name == 'educenter') {
                                this.center_roles.push(value)
                            }
                            if (value.guard_name == 'noibo') {
                                this.noibo_roles.push(value)
                            }
                        })
                    }
                );
            },
            async onSubmit() {
              this.is_disable = true;
                /*Form data*/
                let formData = new FormData();
                formData.append('id', this.$route.params.id);
                formData.append('name', this.account_type.name);
                formData.append('backend_role_id', this.account_type.backend_role_id);
                formData.append('noibo_role_id', this.account_type.noibo_role_id);
                formData.append('center_role_id', this.account_type.center_role_id);
                formData.append('is_staff', this.account_type.is_staff);
                let avatar = this.photo;

                function urltoFile(url, filename, mimeType) {
                    return (fetch(url)
                            .then(function (res) {
                                return res.arrayBuffer();
                            })
                            .then(function (buf) {
                                return new File([buf], filename, {type: mimeType});
                            }).catch((error) => {
                                return 1;
                            })
                    );
                }

                if (avatar.search('https') === -1) {
                    let file = await urltoFile(avatar, this.image_name, this.image_type);
                    if (file !== 1 && file.name !== 'null') {
                        formData.append('avatar', file);
                    }
                }
                /*End*/
                await this.$store.dispatch(UPDATE_ACCOUNT_TYPE, {
                    id: this.$route.params.id,
                    params: formData
                }).then((res) => {
                    if (res.data.status == 200) {
                        this.$router.push({name: "account-type-index"}).then(() => {
                            this.$bvToast.toast('Cập nhật loại tài khoản thành công', {
                                title: 'Cập nhật',
                                variant: 'success',
                                solid: true
                            });
                        })

                    } else {
                        this.$bvToast.toast('Cập nhật loại tài khoản thất bại', {
                            title: 'Cập nhật',
                            variant: 'danger',
                            solid: true
                        });
                    }
                }).catch((e) => {
                    if (e.data.data.message_validate_form) {
                        this.$refs.form.setErrors(e.data.data.message_validate_form);
                    }
                }).finally(() => {
                  this.is_disable = false
                });
            },
            onFileChange(e) {
                const file = e.target.files[0];
                if (typeof FileReader === "function") {
                    const reader = new FileReader();
                    reader.onload = event => {
                        this.current_photo = event.target.result;
                    };
                    reader.readAsDataURL(file);
                    this.image_name = file.name;
                    this.image_type = file.type;
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            },
        }
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>
